.modal-form{
    $block-name: &; // #{$block-name}__element

    height: 100%;
    display: flex;
    flex-direction: column;

    @include min($screen-md){

        height: auto;
        box-shadow: 0 0 16px rgba(17, 17, 26, 0.1);
    }

    &__close{
        display: none;

        @include min($screen-md){
            position: absolute;
            display: block;
            top: 114px;
            right: 67px;
        }
    }

    &__content{
        margin: auto;
        padding-left: 12px;
        padding-right: 12px;

        @include min($screen-md){
            padding: 110px;
        }
    }

    &__title{
        font-size: 24px;
        line-height: 120%;
        font-family: $gilroy;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 20px;

        @include min($screen-md){
            font-size: 32px;
        }
    }

    &__field{
        margin-bottom: 20px;
    }

    &__bottom{
        @include min($screen-md){
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 20px;
            align-items: start;
        }
    }

    &__personal{
        margin-bottom: 20px;
        font-size: 13px;

        @include min($screen-md){
            margin-bottom: 0;
        }
    }

}
