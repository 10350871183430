//vars in vars.scss

//$screen-xs:                   0 !default;
//$screen-sm:                   480px !default;
//$screen-md:                   768px !default;
//$screen-lg:                   992px !default;
//$screen-xl:                   1200px !default;
//$screen-xxl:                  1800px !default;
//
//$container-sm:                100% !default;
//$container-md:                100% !default;
//$container-lg:                ($screen-lg - 30) !default;
//$container-xl:                ($screen-xl - 30) !default;
//$container-xxl:               ($screen-xxl - 30) !default;
//
//$grid-columns:                12 !default;
//$grid-gutter-width:           30px !default;

//------------------------------------------------------------------------------
//  Генератор правил для контейнера
//------------------------------------------------------------------------------

@mixin container(
    $xs-grid-gutter: $grid-gutter-width,
    $sm-grid-gutter: $grid-gutter-width,
    $md-grid-gutter: $grid-gutter-width,
    $lg-grid-gutter: $grid-gutter-width,
    $xl-grid-gutter: $grid-gutter-width,
    $xxl-grid-gutter: $grid-gutter-width
) {
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @if ($xs-grid-gutter != 0) {
        padding-left: ($xs-grid-gutter / 2);
        padding-right: ($xs-grid-gutter / 2);
    }

    @media (min-width: $screen-sm) {
        width: $container-sm;

        @if ($sm-grid-gutter != $xs-grid-gutter) {
            padding-left: ($sm-grid-gutter / 2);
            padding-right: ($sm-grid-gutter / 2);
        }
    }

    @media (min-width: $screen-md) {
        width: $container-md;

        @if ($md-grid-gutter != $sm-grid-gutter) {
            padding-left: ($md-grid-gutter / 2);
            padding-right: ($md-grid-gutter / 2);
        }
    }

    @media (min-width: $screen-lg) {
        width: $container-lg;

        @if ($lg-grid-gutter != $md-grid-gutter) {
            padding-left: ($lg-grid-gutter / 2);
            padding-right: ($lg-grid-gutter / 2);
        }
    }

    @media (min-width: $screen-xl) {
        width: $container-xl;

        @if ($xl-grid-gutter != $lg-grid-gutter) {
            padding-left: ($xl-grid-gutter / 2);
            padding-right: ($xl-grid-gutter / 2);
        }
    }

    @media (min-width: $screen-xxl) {
        width: $container-xxl;

        @if ($xxl-grid-gutter != $xl-grid-gutter) {
            padding-left: ($xxl-grid-gutter / 2);
            padding-right: ($xxl-grid-gutter / 2);
        }
    }
}

//------------------------------------------------------------------------------
//  Генератор правил для прямого родителя модульной сетки
//------------------------------------------------------------------------------
//  Принимает:         По умолчанию:        Пояснение:
//  $xs-grid-gutter:   $grid-gutter-width   промежуток между ячейками XS-сетки
//  $sm-grid-gutter:   $grid-gutter-width   промежуток между ячейками SM-сетки
//  $md-grid-gutter:   $grid-gutter-width   промежуток между ячейками MD-сетки
//  $lg-grid-gutter:   $grid-gutter-width   промежуток между ячейками LG-сетки
//  $xl-grid-gutter:   $grid-gutter-width   промежуток между ячейками XL-сетки
//  $xxl-grid-gutter:  $grid-gutter-width   промежуток между ячейками XXL-сетки
//------------------------------------------------------------------------------
// Пример вызова:
// .some-class {
//   @include row (
//     $xs-grid-gutter:  $grid-gutter-width, // отступ по умолчанию
//     $sm-grid-gutter:  0,                  // нет отступа
//     $md-grid-gutter:  $grid-gutter-width  // отступ по умолчанию
//   );
// }
//
// Идентичный вызов:
// .some-class {
//   @include row ( $grid-gutter-width, 0, $grid-gutter-width );
// }
//------------------------------------------------------------------------------

@mixin row(
    $xs-grid-gutter: $grid-gutter-width,
    $sm-grid-gutter: $grid-gutter-width,
    $md-grid-gutter: $grid-gutter-width,
    $lg-grid-gutter: $grid-gutter-width,
    $xl-grid-gutter: $grid-gutter-width,
    $xxl-grid-gutter: $grid-gutter-width
) {
    display: flex;
    flex-wrap: wrap;

    & > * {
        // stylelint-disable-line  selector-combinator-blacklist, plugin/selector-bem-pattern
        flex-basis: 100%;
    }

    @if ($xs-grid-gutter != 0) {
        margin-left: ($xs-grid-gutter / -2);
        margin-right: ($xs-grid-gutter / -2);
    }

    @if ($sm-grid-gutter != $xs-grid-gutter) {
        @media (min-width: $screen-sm) {
            margin-left: ($sm-grid-gutter / -2);
            margin-right: ($sm-grid-gutter / -2);
        }
    }

    @if ($md-grid-gutter != $sm-grid-gutter) {
        @media (min-width: $screen-md) {
            margin-left: ($md-grid-gutter / -2);
            margin-right: ($md-grid-gutter / -2);
        }
    }

    @if ($lg-grid-gutter != $md-grid-gutter) {
        @media (min-width: $screen-lg) {
            margin-left: ($lg-grid-gutter / -2);
            margin-right: ($lg-grid-gutter / -2);
        }
    }

    @if ($xl-grid-gutter != $lg-grid-gutter) {
        @media (min-width: $screen-xl) {
            margin-left: ($xl-grid-gutter / -2);
            margin-right: ($xl-grid-gutter / -2);
        }
    }

    @if ($xxl-grid-gutter != $xl-grid-gutter) {
        @media (min-width: $screen-xxl) {
            margin-left: ($xxl-grid-gutter / -2);
            margin-right: ($xxl-grid-gutter / -2);
        }
    }
}

//------------------------------------------------------------------------------
//  Генератор ширины ячейки
//------------------------------------------------------------------------------
//  Принимает:    По умолчанию:         Пояснение:
//  $column:      1                     ширина (кол-во колонок в этой ячейке)
//  $columns:     $grid-columns;        количество ячеек в ряду
//
//  Возвращает width.
//------------------------------------------------------------------------------

@mixin get-width($column: 1, $columns: $grid-columns) {
    $width: (100% / $columns) * $column;
    flex: 0 0 $width;
    max-width: $width;
}

//------------------------------------------------------------------------------
//  Генератор правил для одной ячейки
//------------------------------------------------------------------------------
//  Принимает:    По умолчанию:         Пояснение:
//  $column:      1                     ширина (кол-во колонок для этой ячейки)
//  $columns:     $grid-columns;        общее количество ячеек на 1 ряд
//  $gutter:      $grid-gutter-width    промежуток между ячейками
//------------------------------------------------------------------------------

@mixin make-col($column: 1, $columns: $grid-columns, $gutter: $grid-gutter-width) {
    @include get-width($column, $columns);

    // ВНИМАНИЕ!
    // если $gutter == false, в CSS не попадут правила padding-left и padding-right
    @if ($gutter != false) {
        padding-left: ($gutter / 2);
        padding-right: ($gutter / 2);
    }
}

//------------------------------------------------------------------------------
//  Генератор правил для одной ячейки с возможностью указать @media
//------------------------------------------------------------------------------
//  Принимает:    По умолчанию:         Пояснение:
//  $screen:      xs                    тип сетки [xs|sm|md|lg|xl|xxl]
//  $column:      $grid-columns         ширина (кол-во колонок для этой ячейки)
//  $columns:     $grid-columns;        общее количество ячеек на 1 ряд
//  $gutter:      $grid-gutter-width    промежуток между ячейками
//------------------------------------------------------------------------------

@mixin col(
    $screen: xs,
    $column: $grid-columns,
    $columns: $grid-columns,
    $gutter: $grid-gutter-width
) {
    @if ($screen == xs) {
        @include make-col($column, $columns, $gutter);
    }

    @if ($screen == sm) {
        @media (min-width: $screen-sm) {
            @include make-col($column, $columns, $gutter);
        }
    }

    @if ($screen == md) {
        @media (min-width: $screen-md) {
            @include make-col($column, $columns, $gutter);
        }
    }

    @if ($screen == lg) {
        @media (min-width: $screen-lg) {
            @include make-col($column, $columns, $gutter);
        }
    }

    @if ($screen == xl) {
        @media (min-width: $screen-xl) {
            @include make-col($column, $columns, $gutter);
        }
    }

    @if ($screen == xxl) {
        @media (min-width: $screen-xxl) {
            @include make-col($column, $columns, $gutter);
        }
    }
}

//------------------------------------------------------------------------------
//  Генератор правил для отступа одной ячейки с возможностью указать @media
//------------------------------------------------------------------------------
//  Принимает:    По умолчанию:         Пояснение:
//  $screen:      xs                    тип сетки [xs|sm|md|lg|xl|xxl]
//  $column:      1                     ширина отступа (кол-во колонок)
//  $columns:     $grid-columns;        общее количество ячеек на 1 ряд
//------------------------------------------------------------------------------

@mixin col-offset($screen: xs, $column: 1, $columns: $grid-columns) {
    @if ($screen == xs) {
        margin-left: ($column * 100% / $columns);
    }

    @if ($screen == sm) {
        @media (min-width: $screen-sm) {
            margin-left: ($column * 100% / $columns);
        }
    }

    @if ($screen == md) {
        @media (min-width: $screen-md) {
            margin-left: ($column * 100% / $columns);
        }
    }

    @if ($screen == lg) {
        @media (min-width: $screen-lg) {
            margin-left: ($column * 100% / $columns);
        }
    }

    @if ($screen == xl) {
        @media (min-width: $screen-xl) {
            margin-left: ($column * 100% / $columns);
        }
    }

    @if ($screen == xxl) {
        @media (min-width: $screen-xxl) {
            margin-left: ($column * 100% / $columns);
        }
    }
}
