.big-image{
    $block-name: &; // #{$block-name}__element

    &__image{
        height: 700px;

        @include min($screen-md){
            height: 800px;
        }

        @include min($screen-xl){
            height: 900px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &--product{
        margin-bottom: 30px;

        @include min($screen-md){
            margin-bottom: 40px;
        }
    }
}
