.main-gallery{
    $block-name: &; // #{$block-name}__element

    &__title{
        @include min($screen-md){
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            margin-bottom: 45px;
        }
    }

    &__grid{
        display: grid;
        grid-row-gap: 20px;

        @include min($screen-md){
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 24px;
        }

        @include min($screen-xl){
            grid-template-areas:
                'one two text'
                '. three four'
                'five six .'
            ;

            grid-template-columns: repeat(3, 1fr);
        }

    }

    &__content{
        @include min($screen-md){
            grid-column: 1/3;
            margin-bottom: 16px;
        }

        @include min($screen-xl){
            grid-area: text;
            margin-bottom: 0;
            //grid-column: 1/3;
        }
    }

    &__text{
        display: block;
        @include min($screen-md){
            width: calc(50% - 24px);
            //display: inline-block;
        }

        @include min($screen-xl){
            width: 100%;
        }

    }

    &__link{
        text-transform: uppercase;
        display: inline-block;
        margin-top: 20px;

        @include min($screen-md){
            margin-top: 30px;
        }
    }

    &__image{
        border: 1px solid #000;

        position: relative;
        overflow: hidden;

        &:hover{
            &:after{
                background-color: rgba(44, 153, 66, 0.3);
            }

            img{
                transform-origin: center;
                transform: scale3d(1.1, 1.1, 1);
            }
        }

        &:before{
            content: '';
            display: block;
            padding-top: 44.5%;

            @include min($screen-md){
                padding-top: 100%;
            }
        }

        &:after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.3);
        }

        img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            top: 0;
            left: 0;
            transition: transform $transition-time;
        }

        span{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            z-index: 1;
            color: $white;
            font-size: 32px;
            line-height: 120%;
            font-family: $gilroy;
            text-transform: uppercase;
        }

        &--one{
            @include min($screen-xl){
             grid-area: one;
            }
        }

        &--two{
            @include min($screen-xl){
             grid-area: two;
            }
        }

        &--three{
            @include min($screen-xl){
             grid-area: three;
            }
        }

        &--four{
            @include min($screen-xl){
             grid-area: four;
            }
        }

        &--five{
            @include min($screen-xl){
             grid-area: five;
            }
        }

        &--six{
            @include min($screen-xl){
             grid-area: six;
            }
        }
    }

    &--windows{
        #{$block-name}__content{
            order: 2;
        }
    }
}
