.top-btn{
    $block-name: &; // #{$block-name}__element

    margin-bottom: 30px;

    @include min($screen-md){
        margin-bottom: 40px;
    }

    &__content{
        display: grid;
        grid-gap: 20px 24px;

        @include min($screen-md){
            grid-template-columns: 1fr 1fr;
        }
    }

    &__btn{
        @include min($screen-xl){
            max-width: 306px;
        }
    }
}
