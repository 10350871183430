.modal{
    $block-name: &; // #{$block-name}__element

    //padding-bottom: 30px;



    &__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 12px;

        @include min($screen-md){
            display: none;
        }
    }


    &--menu{
        //background-image: url(../img/modal/modal-menu-bg.jpeg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        //padding-top: 130px;
        //padding-bottom: 35px;

        @include min($screen-md){
            //padding-top: 150px;
            //padding-bottom: 75px;
        }

        #{$block-name}__header{
            display: flex;

            @include min($screen-lg){
                display: none;
            }
        }
    }

    //&__bottom{
    //    margin-top: 40px;
    //    justify-content: center;
    //
    //    @include min($screen-md){
    //        display: flex;
    //        justify-content: flex-start;
    //
    //    }
    //}
    //
    //&__social{
    //    display: flex;
    //    justify-content: center;
    //    @include min($screen-md){
    //        display: none;
    //    }
    //}
    //
    //&__contacts, &__mail{
    //    display: none;
    //    @include min($screen-md){
    //        display: block;
    //    }
    //
    //    @include min($screen-xl){
    //        display: none;
    //    }
    //}
    //
    //&__contacts{
    //    margin-right: 50px;
    //}
}

.hystmodal{
    $block-name: &; // #{$block-name}__element`
    z-index: 10000;

    &__window{
        margin: 0;
    }

    &__shadow{
        background-color: $white;

        &--show{
            opacity: 0.7;
        }
    }

    &--form{
        //max-width: 910px;

        #{$block-name}__window{
            width: 100%;
            height: 100%;

            @include min($screen-md){
                max-width: 636px;
                height: auto;
            }
        }
    }

    &--menu{
        #{$block-name}__window{
            width: 100%;
            height: 100%;

        }
    }
}
