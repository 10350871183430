.characteristics{
    $block-name: &; // #{$block-name}__element

    background-color: $gray;
    color: $white;
    padding-top: 50px;
    padding-bottom: 50px;

    &__item{
        position: relative;
        display: flex;
        align-items: flex-end;

        &--empty{
            #{$block-name}__left{
                &:after{
                    display: none;
                }
            }
        }
    }

    &__left{
        position: relative;
        width: 100%;
        max-width: 277px;

        @include min($screen-md){
            max-width: 335px;
        }

        @include min($screen-xl){
            max-width: 416px;
        }

        span{
            position: relative;
            z-index: 1;
            display: inline-block;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: $gray;
            padding-right: 5px;

            br{
                @include min($screen-md){
                    display: none;
                }
            }

        }

        &:after{
            content: '';
            position: absolute;
            display: inline-block;
            height: 1px;
            width: 100%;
            background-color: $green;
            bottom: 9px;
            left: 0;
            z-index: 0;
        }
    }

    &__right{
        flex-shrink: 0;
        position: relative;
        z-index: 1;
        padding-left: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
        min-width: 80px;
    }
}
