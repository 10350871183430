.catalog-descr{
    $block-name: &; // #{$block-name}__element

    &__text{
        margin-bottom: 20px;

        @include min($screen-md){
            margin-bottom: 30px;
            width: 50%;
        }
    }

    &__container{
        @include max($screen-xl){
            padding: 0;
        }
    }

    &__list{
        position: relative;
        counter-reset: num;
    }

    &__item{
        margin-bottom: 20px;

        &:last-child{
            margin-bottom: 0;
        }

        @include min($screen-md){
            margin-bottom: 0;
            border-bottom: 1px solid $gray;
        }
    }

    &__item-button{
        width: 100%;
        font-weight: 300;
        font-size: 32px;
        font-family: $gilroy;
        text-transform: uppercase;
        padding: 20px 12px;
        display: flex;
        align-items: center;
        -webkit-tap-highlight-color: transparent;

        @include min($screen-md){
            font-size: 36px;
            padding: 30px 36px;
        }

        @include min($screen-xl){
            padding-left: 0;
        }

        &:before{
            counter-increment: num;
            content: counter(num, decimal-leading-zero);
            flex-shrink: 0;
            font-size: 20px;
            line-height: 1;
            font-family: $gilroy;
            font-weight: 300;
            color: rgba($gray, 0.3);
            margin-right: 40px;

            @include min($screen-xl){
                width: 416px;
                display: inline-block;
                margin-right: 24px;
            }
        }

        &:hover, &:focus-visible{
            position: relative;
            z-index: 10;

            &:before{
                @include min($screen-xl){
                    opacity: 0;
                }
            }

            + #{$block-name}__item-img{
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &__item-img{
        position: relative;
        overflow: hidden;

        @include min($screen-md){
            visibility: hidden;
            opacity: 0;
            position: absolute;
            z-index: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 350px;
            width: 350px;
        }

        @include min($screen-xl){
            left: 0;
            right: auto;
            width: 400px;
            height: 400px;
            //top: 50%;
            transform: none;
            bottom: 0;
            top: auto;
        }

        &:before{
            content: '';
            padding-top: 45%;
            display: block;

            @include min($screen-md){
                display: none;
            }
        }

        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include min($screen-md){
                position: static;
            }
        }

        &--active{
            visibility: visible;
            opacity: 1;
        }
    }
}
