// Mixins.
/* stylelint-disable */

@mixin nl {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style: none;
    list-style-position: outside;
}

@mixin cf {
    &:before,
    &:after {
        display: table;
        content: '';
    }

    &:after {
        clear: both;
    }

    .lt-ie8 & {
        zoom: 1;
    }
}

@mixin blocks-justify {
    text-align: justify;
    text-justify: newspaper;
}

// You can add your own mixins here:
@mixin min($val) {
    @media (min-width: $val) {
        @content;
    }
}

@mixin max($val) {
    @media (max-width: $val - 1) {
        @content;
    }
}

// Browser Prefixes
@mixin transform($transforms) {
    -webkit-transform: $transforms;
    -moz-transform: $transforms;
    -ms-transform: $transforms;
    transform: $transforms;
}

// Rotate
@mixin rotate($deg) {
    @include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
    @include transform(scale($scale));
}

// Translate
@mixin translate($x, $y) {
    @include transform(translate($x, $y));
}

// Skew
@mixin skew($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform Origin
@mixin transform-origin($origin) {
    -webkit-transform-origin: $origin;
    -moz-transform-origin: $origin;
    -ms-transform-origin: $origin;
    transform-origin: $origin;
}

@mixin focus-shadow {
    box-shadow: 0 0 3px 2px rgba($color-main, 0.2);
}

@mixin field-name {
    display: block;
    margin-bottom: 0.4em;
    line-height: $line-height;
    font-weight: 700;
}

@mixin field-help-text {
    display: block;
    font-size: 16px;
    line-height: 1.3;
    //color: rgba($white-color, 0.6);

    &:empty {
        display: none;
    }
}

@mixin field-text {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    //color: $gray-light;
    //min-height: 45px;
    margin: 0;
    //border: 1px solid rgba($gray-light, 0.5);
    border: 1px solid $gray;
    border-radius: 0;
    padding: 18px 20px;
    //padding: $field-padding-vertical $field-padding-horizontal;
    //line-height: $line-height;
    line-height: 1.3;
    font-size: 16px;
    font-weight: 400;
    font-family: inherit;
    background-color: transparent;
    -moz-appearance: textfield;
    appearance: textfield;
    transition: box-shadow 0.1s linear;

    &:focus-visible {
        @include field-focus;
    }

    &[disabled],
    &[readonly] {
        cursor: not-allowed;
        //background-color: $gray-lighter;
    }

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration,
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }

    &[type='color'] {
        padding: 0;
    }

    &::placeholder {
        //color: $white-color;
        //opacity: 0.8;
        opacity: 1;
        color: $gray;
    }
}

@mixin field-focus {
    outline: 0;
    //border-color: $gray-light;
    //box-shadow: 0 0 0 1px rgba($white-color, $opacity);
}

/* stylelint-enable */
