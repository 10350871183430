// Common styles of the project

html {
    font-size: 10px;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body {
    height: 100%;
}

//.fancybox__container {
//    font-family: $onest;
//    font-size: 18px;
//    text-rendering: optimizeSpeed;
//    line-height: 1.4;
//    font-weight: 300;
//}
.page {
    //scroll-behavior: smooth;
    font-family: $sans;
    font-size: 16px;
    //text-rendering: optimizeSpeed;
    line-height: 1.3;
    font-weight: 400;
    //background-color: $body-bg;
    display: flex;
    flex-direction: column;
    //min-height: 100vh;
    height: 100%;
    //color: $black-color;
    color: $gray;
}

.page__header-fixed{
    $block-name: &; // #{$block-name}__element

    flex: 0 0 auto;


    position: fixed;
    width: 100%;
    //background-color: $white-color;
    //background-color: $gray;
    //height: 70px;
    top: 0;
    left: 0;
    z-index: 1000;
    transform: translateY(-100%);
}

.page__main{
    flex: 1 0 auto;
}
.page__footer{
    flex: 0 0 auto;
}

//.page__wrapper {
//    flex: 1 0 auto;
//}
//
//.page__footer {
//    flex: 0 0 auto;
//}

* html .page__wrapper {
    height: 100%;
}

.visually-hidden {
    position: absolute;
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
}

img {
    max-width: 100%;
    display: block;
    vertical-align: middle;
}

::placeholder {
    //color: rgba($text-color, 0.5);
}

ul,
ol {
    padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

ul,
ol {
    list-style: none;
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}

article > * + * {
    margin-top: 1em;
}

input,
button,
textarea,
select {
    font: inherit;
}

@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

a{
    //color: $orange-color;
    text-decoration: none;
    transition: color $transition-time;

    &:hover, &:focus-visible{
        //color: $blue;
    }

    &:focus{
        outline: none;
    }
}

.container {
    @include container(24px, 24px, 72px, 72px, 72px, 72px);
    max-width: 1368px;

    &--small{
        @include min($screen-xl){
            padding-left: 476px;
        }
    }

    &--small-right{
        @include min($screen-xl){
            padding-right: 476px;
        }
    }
}

.js-focus-visible:focus:not(.focus-visible) {
    outline: none;
}

.visually-hidden {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
}

.title{
    font-family: $gilroy;
    font-size: 40px;
    line-height: 1.2;
    font-weight: 300;
    text-transform: uppercase;
    color: $green;
    margin-bottom: 30px;

    @include min($screen-md){
        margin-bottom: 50px;
        font-size: 48px;
    }
}

.title-top{
    font-weight: 300;
    font-size: 48px;
    line-height: 120%;
    font-family: $gilroy;
    text-transform: uppercase;
    margin-bottom: 30px;

    @include min($screen-md){
        margin-bottom: 50px;
    }

    @include min($screen-xl){
        font-size: 64px;
    }
}


.section{
    margin-bottom: 80px;

    @include min($screen-md){
        margin-bottom: 120px;
    }

    @include min($screen-xl){
        margin-bottom: 140px;
    }
}

.link-green{
    color: $green;

    span{
        display: inline;
        transition: $transition-time;
        border-bottom: 1px solid rgba($green, 0.3);
    }

    &:hover, &:focus-visible{
        span{
            border-bottom: 1px solid transparent;
        }

    }
}

.link-gray{
    color: $gray;

    span{
        display: inline;
        transition: $transition-time;
        border-bottom: 1px solid rgba($gray, 0.3);
    }

    &:hover, &:focus-visible{
        span{
            border-bottom: 1px solid transparent;
        }

    }
}

.link-gray-versa{
    color: $gray;

    span{
        display: inline;
        transition: $transition-time;
        border-bottom: 1px solid transparent;
    }

    &:hover, &:focus-visible{
        span{
            border-bottom: 1px solid rgba($gray, 0.3);

        }

    }
}

.swiper-horizontal>.swiper-pagination-bullets{
    bottom: 20px;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;

    .swiper-pagination-bullet{
        width: 10px;
        height: 10px;
        border-radius: 0;
        background-color: rgba($white, 0.3);
        border: 1px solid rgba($gray, 0.3);
        opacity: 1;
    }

    .swiper-pagination-bullet-active{
        background-color: $green;
        border-color: $green;
    }
}





