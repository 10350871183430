//$btn-width: 250px !default;
//$btn-height: 50px !default;
//
.btn{
    position: relative;
    //margin: 0;
    //padding: 0;
    overflow: visible;
    font: inherit;
    text-align: inherit;
    text-decoration: none;
    background: transparent;
    border: none;
    //transition: $transition-time;
    cursor: pointer;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    box-sizing: border-box;
    font-weight: 400;


    &--green{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $green;
        font-size: 16px;
        line-height: 130%;
        padding: 20px;
        text-transform: uppercase;
        color: $white;
        width: 100%;
        //max-width: 400px;
        overflow: hidden;
        position: relative;
        z-index: 1;

        &:hover, &:focus-visible{


            &:before{
                //transform: scale3d(15, 15, 1);
                width: 150%;
                height: 300%;
            }
        }

        &:before{
            content: '';
            z-index: -1;
            position: absolute;
            top: 50%;
            //left: 100%;
            right: -40px;
            //margin: -15px 0 0 1px;
            width: 30px;
            height: 60px;
            border-radius: 50%;
            background-color: $gray;
            transform-origin: 100% 50%;
            transform: translateY(-50%);
            //transform: scale3d(1, 2, 1);
            transition: 0.3s, opacity 0.3s;
            transition-timing-function: cubic-bezier(0.7,0,0.9,1);
        }
    }
}
