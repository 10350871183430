.filter-gallery{
    $block-name: &; // #{$block-name}__element

    margin-bottom: 50px;

    @include min($screen-md){
        //display: grid;
        //grid-template-columns: 1fr 1fr;
        //grid-column-gap: 24px;
    }

    @include min($screen-xl){
        display: block;
    }

    &__container{
        margin-bottom: 30px;

        @include min($screen-md){
            margin-bottom: 0;
        }

        @include min($screen-xl){
            margin-bottom: 30px;
        }
    }

    &__ac{
        border-left: 0;
        border-right: 0;
        border-top: 0;
        border-color: $gray;
        margin-top: 0;
    }

    &__trigger{
        &.ac-trigger{
            color: $gray;
            font-size: 24px;
            line-height: 1.2;
            font-weight: 300;
            padding: 20px 35px 20px 0;
            font-family: $gilroy;

            &:after{
                font-size: 34px;
                font-weight: 300;
                font-family: $gilroy;
            }

            &:focus{
                color: $gray;
            }
        }
    }

    &__panel{
        #{$block-name}__text{
            &.ac-text{
                padding: 10px 0 20px;
            }
        }
    }


    &__text-link{
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        text-transform: uppercase;
        color: $green;
        padding: 10px 20px;
    }

    &__link{
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        text-transform: uppercase;
        color: $green;

        &:hover, &:focus-visible{
            color: lighten($green, 15%);
        }
    }
}
