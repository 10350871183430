.header-menu{
    $block-name: &; // #{$block-name}__element

    &__list{
        display: flex;
        align-items: center;
    }

    &__item{
        &:hover{
            #{$block-name}__link{
                color: $green;
            }

            #{$block-name}__mega-menu{
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &__link{
        line-height: 1.3;
        display: inline-block;
        padding: 8px 15px;
        color: $gray;

        &:hover, &:focus-visible{
            color: $green;
        }
    }

    &__mega-menu{
        width: 100vw;
        position: absolute;
        left: 0;
        //top: var(--header-height);
        visibility: hidden;
        opacity: 0;
        transition: $transition-time;
    }
}
