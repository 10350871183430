.news-content{
    $block-name: &; // #{$block-name}__element

    margin-bottom: 80px;

    @include min($screen-md){
        margin-bottom: 120px;
    }

    @include min($screen-xl){
        margin-bottom: 0;
    }

    &__list{
        @include min($screen-md){
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 24px;
        }
    }

    &__item{
        @include max($screen-md){
            &:last-child{
                .news-item{
                    &:last-child{
                        border-bottom: none;
                    }
                }
            }
        }
    }
}
