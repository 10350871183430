.header {
    $block-name: &; // #{$block-name}__element

    padding-top: 30px;
    padding-bottom: 30px;

    margin-bottom: 10px;

    @include min($screen-md){
        margin-bottom: 30px;
    }

    @include min($screen-xl){
        margin-bottom: 50px;
    }

    &__wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo{
        display: flex;
        align-items: center;

        svg{
            @include min($screen-md){
                width: 145px;
                height: 36px;
            }
        }
    }

    &__menu{
        display: none;

        @include min($screen-lg){
            display: block;
        }
    }

    &__link{
        text-transform: uppercase;
        line-height: 1.3;
    }

    &__burger{
        @include min($screen-lg){
            display: none;
        }
    }

}
