.back-btn{
    $block-name: &; // #{$block-name}__element

    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: $green;
    text-transform: initial;

    &__icon{
        margin-right: 10px;
    }
}
