.footer-menu{
    $block-name: &; // #{$block-name}__element

    &__list{
        @include min($screen-md){
            display: flex;
            align-items: center;
        }
    }

    &__item{
        &:first-child{
            #{$block-name}__link{
                padding-left: 0;
            }
        }

        &:last-child{
            #{$block-name}__link{
                padding-right: 0;
            }
        }
    }

    &__link{
        display: inline-block;
        line-height: 1.3;
        padding: 10px 0;

        @include min($screen-md){
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}
