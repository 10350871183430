.news-form{
    $block-name: &; // #{$block-name}__element

    background-color: $gray;
    margin-left: -12px;
    margin-right: -12px;
    padding: 50px 12px;

    @include min($screen-md){
        margin-left: -36px;
        margin-right: -36px;
        padding-left: 36px;
        padding-right: 36px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 24px;
    }

    @include min($screen-xl){
        display: block;
        margin: 0;
        padding: 0;
        background-color: transparent;
    }

    &__title{
        font-weight: 300;
        font-size: 40px;
        line-height: 120%;
        color: $green;
        text-transform: uppercase;
        margin-bottom: 12px;

        @include min($screen-md){
            font-size: 48px;
            margin-bottom: 50px;
            grid-column: 1/3;
        }

        @include min($screen-xl){
            display: none;
        }
    }

    &__input{
        margin-bottom: 20px;

        @include min($screen-md){
            margin-bottom: 0;
        }

        @include min($screen-xl){
            margin-bottom: 20px;
        }
    }

    &__button{
        span{
            margin-left: 5px;
            display: none;

            @include min($screen-xl){
                display: inline-block;
            }
        }
    }
}
