.gallery{
    $block-name: &; // #{$block-name}__element

    &__title{
        margin-bottom: 40px;

        @include min($screen-md){
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 50px;
        }
    }

    &__title-filter{
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        color: $green;
        text-transform: initial;
        margin-top: 20px;

        @include min($screen-xl){
            display: none;
        }
    }

    &__title-icon{
        margin-right: 10px;
    }

    &__title-text{
        font-family: $gilroy;
    }

    &__wrapper {
        @include min($screen-xl){
            display: grid;
            grid-template-columns: 306px 1fr;
            grid-template-rows: auto 1fr;
            align-items: start;
            grid-column-gap: 133px;
        }
    }

    &__filter {
        grid-column: 1/2;

        visibility: hidden;
        opacity: 0;
        position: absolute;
        transition: opacity $transition-time;

        @include min($screen-xl){
            visibility: visible;
            opacity: 1;
            position: static;
        }

        &--active{
            position: static;
            visibility: visible;
            opacity: 1;
        }
    }

    &__content {
        grid-row: 1/3;
        grid-column: 2/3;
    }

    &__form {
        grid-column: 1/2;
        grid-row: 2;
        align-self: start;
    }
}
