.field-text {

    $block-name: &; // #{$block-name}__element

    display: block;
    //margin-bottom: $typo-margin-vertical;

    &__name {
        @include field-name;
    }

    &__input-wrap {
        display: block;
    }

    &__input {
        @include field-text;

        @at-root textarea#{&} {
            $textarea-height: ($line-height * 2) + ($field-padding-vertical * 2);

            height: auto;
            min-height: calc(#{$textarea-height} + 2px);
            resize: vertical;
        }
    }

    &__help-text {
        @include field-help-text;
    }


    &--error {

        #{$block-name}__name,
        #{$block-name}__input,
        #{$block-name}__help-text {
            color: $color-danger;
        }

        #{$block-name}__input {
            border-color: $color-danger;
            background-color: lighten($color-danger, 35%);
        }
    }

    &--news{
        #{$block-name}__input{
            @include max($screen-xl){
                color: $white;
                border-color: $white;

                &::placeholder{
                    color: $white;
                }
            }
        }
    }
}
