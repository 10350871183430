.main-approach{
    $block-name: &; // #{$block-name}__element

    &__container{
        &--image{
            @include max($screen-xl){
                padding: 0;
            }

            //@include min($screen-lg){
            //    padding-right: 440px;
            //}
        }
    }

    &__image{
        width: 100%;
        height: 600px;
        margin-bottom: 30px;

        @include min($screen-md){
            height: 800px;
            margin-bottom: 40px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;

        }
    }

    &__list{
        display: grid;
        grid-gap: 30px 24px;

        @include min($screen-md){
            grid-template-columns: 1fr 1fr;
            grid-gap: 40px 24px;
        }
    }

    &__title{
        font-weight: 300;
        font-size: 24px;
        line-height: 120%;
        font-family: $gilroy;
        text-transform: uppercase;
        margin-bottom: 10px;

        @include min($screen-md){
            font-size: 32px;
            margin-bottom: 20px;
        }
    }
}
