.hero{
    $block-name: &; // #{$block-name}__element

    margin-bottom: 30px;

    @include min($screen-md){
        margin-bottom: 40px;
    }

    &__content{
        margin-bottom: 30px;

        @include min($screen-md){
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            margin-bottom: 50px;
        }
    }

    &__title{
        font-family: $gilroy;
        font-weight: 300;
        font-size: 48px;
        line-height: 120%;
        margin-bottom: 20px;
        text-transform: uppercase;

        @include min($screen-md){
            margin-bottom: 0;
        }

        @include min($screen-xl){
            font-size: 64px;
        }
    }

    &__text{
        align-self: end;
        line-height: 1.3;

        @include min($screen-md){
            margin-bottom: 10px;
        }

        @include min($screen-xl){
            justify-self: end;
            margin-right: 110px;
        }

        br{
            display: none;

            @include min($screen-md){
                display: inline;
            }
        }

        p + p{
            margin-top: 20px;
        }
    }

    &__image{
        height: 600px;

        @include min($screen-md){
            height: 800px;
        }

        @include min($screen-xl){
            height: 1000px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &--main{
        margin-bottom: 30px;

        @include min($screen-md){
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            margin-bottom: 50px;
        }
    }

    &--small-img{
        #{$block-name}__image{
            height: 400px;

            @include min($screen-md){
                height: 600px;
            }
        }
    }

    &--catalog{
        #{$block-name}__text{
            @include min($screen-xl){
                margin-bottom: 17px;
                margin-right: 0;
                max-width: 416px;
            }
        }
    }

    &--date{
        #{$block-name}__content{
            @include min($screen-md){
                display: block;
            }

            @include min($screen-xl){
                display: grid;
                grid-template-columns: 68% 1fr;
                //margin-bottom: 40px;
            }
        }

        #{$block-name}__title{
            margin-bottom: 20px;

            @include min($screen-xl){
                margin-bottom: 0;
            }
        }

        #{$block-name}__text{
            @include min($screen-xl){
                justify-self: start;
                margin-right: 0;
                letter-spacing: 0.3em;
                color: $gray;
                font-weight: 300;
                font-size: 13px;
                margin-bottom: 15px;
            }
        }
    }

    &--about{
        #{$block-name}__content{
            @include min($screen-md){
                display: block;
                margin-bottom: 50px;
            }

            @include min($screen-xl){
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        }

        #{$block-name}__title{
            margin-bottom: 20px;

            @include min($screen-xl){
                margin-bottom: 0;
            }
        }

        #{$block-name}__text{
            @include min($screen-md){
                max-width: 340px;
            }

            @include min($screen-xl){
                max-width: 305px;
            }
        }
    }

    &--contacts{
        #{$block-name}__content{
            @include min($screen-md){
                display: block;
            }
        }

        #{$block-name}__title{
            margin-bottom: 20px;

            @include min($screen-xl){
                margin-bottom: 40px;
            }
        }

        #{$block-name}__text{
            @include min($screen-md){
                max-width: 340px;
            }

            @include min($screen-xl){
                max-width: 416px;
            }
        }
    }

    &--page-404{
        margin-bottom: 80px;

        @include min($screen-md){
            margin-bottom: 120px;
        }

        @include min($screen-xl){
            margin-bottom: 160px;
        }

        #{$block-name}__text{
            @include min($screen-md){
                max-width: 336px;
                margin-bottom: 10px;
                margin-left: 10px;
            }

            @include min($screen-xl){
                max-width: 306px;
                margin-bottom: 30px;
                margin-left: 0;
            }
        }
    }

    &--detail{
        #{$block-name}__title{
            @include max($screen-md){
                font-size: 40px;
            }
        }
    }

    &--product{
        #{$block-name}__title{
            br{
                display: none;

                @include min($screen-xl){
                    display: inline;
                }
            }
        }
    }

    &--certificates{
        margin-bottom: 20px;

        @include min($screen-xl){
            margin-bottom: 40px;
        }

        #{$block-name}__content{
            margin-bottom: 0;
        }

        #{$block-name}__title{
            margin-bottom: 0;
        }
    }
}
