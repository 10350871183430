.pagination {
    display: flex;
    flex-wrap: wrap;

    &__item {
        display: block;
        text-decoration: none;
        min-width: 40px;
        text-align: center;
        font-weight: 300;
        font-size: 24px;
        line-height: 120%;
        margin-right: 15px;
        color: $gray;
        font-family: $gilroy;

        &:last-child{
            margin-right: 0;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            color: rgba($gray, 0.3);
            //color: $text-color;
        }

        @at-root a#{&} {

            &:hover,
            &:focus {
                //background-color: $gray-lightest;
            }
        }

        @at-root span#{&} {
            cursor: default;
        }

        &--active {
            color: rgba($gray, 0.3);
            //background-color: $gray-lightest;
        }
    }
}
