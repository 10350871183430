.menu-accordion{
    $block-name: &; // #{$block-name}__element

    &__wrapper{
        display: grid;
        grid-row-gap: 20px;

        //@include min($screen-md){
        //    grid-template-columns: 30% 70%;
        //}
        //
        //#{$block-name}__list:only-child{
        //    grid-template-columns: 1fr;
        //}
    }

    &__list{
        position: relative;
    }

    &__caption{
        font-weight: 300;
        font-size: 13px;
        line-height: 120%;
        letter-spacing: 0.3em;
        font-family: $gilroy;
        text-transform: uppercase;
        color: $gray;
        margin-bottom: 10px;
        //position: absolute;
        //left: 0;
        //top: -40px;
    }

    .ac{
        margin: 0;
        border: 0;

        #{$block-name}__link{
            width: auto;
            padding: 10px 0;

            span{
                font-weight: 400;
                font-size: 16px;
                line-height: 130%;
                text-transform: uppercase;
                font-family: $sans;
                padding: 0;
            }

            &:after{
                display: none;
            }

        }
    }

    .ac .ac-trigger{
        position: relative;
        //margin: 0;
        //padding: 0;
        overflow: visible;
        font: inherit;
        text-align: inherit;
        text-decoration: none;
        border: none;
        //transition: $transition-time;
        cursor: pointer;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;
        box-sizing: border-box;
        font-weight: 300;
        font-size: 24px;
        line-height: 120%;
        font-family: $gilroy;
        color: $gray;
        text-transform: uppercase;
        padding: 30px 12px;
        background-color: $white;

        @include min($screen-md){
            padding: 30px 36px;
        }

        &:after{
            right: 12px;

            @include min($screen-md){
                right: 36px;
            }
        }
    }

    .ac .ac-panel .ac-text{
        padding: 0 12px;

        @include min($screen-md){
            padding: 0 36px;
        }
    }
}
