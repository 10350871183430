.main-contacts{
    $block-name: &; // #{$block-name}__element

    &__container{
        @include max($screen-xl){
            padding: 0;
        }
    }

    &__grid{
        margin-bottom: 30px;

        @include min($screen-md){
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 24px;
            margin-bottom: 40px;
        }
    }

    &__contacts{
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 24px;
        line-height: 130%;
        margin-bottom: 30px;

        @include min($screen-md){
            margin-bottom: 0;
        }
    }

    &__phone, &__mail{
        margin-bottom: 20px;
    }

    &__address{
        br{
            @include min($screen-xl){
                display: none;
            }
        }
    }

    &__field{
        margin-bottom: 20px;
    }

    &__form-bottom{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 24px;
    }

    &__personal{
        font-size: 13px;
    }

    &__btn{
        align-self: start;
    }

    &__map{
        height: 500px;

        img, iframe{
            height: 100%;
            width: 100%;
            object-fit: cover;
            border: none;
        }
    }
}
