.about-images{
    $block-name: &; // #{$block-name}__element

    &__top{
        margin-bottom: 30px;

        @include min($screen-md){
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 24px;
            margin-bottom: 40px;
        }
    }

    &__text{
        margin-bottom: 20px;

        @include min($screen-md){
            margin-bottom: 0;
        }

        p + p{
            margin-top: 20px;
        }
    }

    &__btn{
        align-self: start;
        @include min($screen-xl){
            max-width: 305px;
        }
    }

    &__img + &__img{
        margin-top: 20px;

        @include min($screen-md){
            margin-top: 40px;
        }
    }
}
