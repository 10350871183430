.faq{
    $block-name: &; // #{$block-name}__element

    &__container{
        @include max($screen-xl){
            padding: 0;
        }
    }

    &__title{
        margin-bottom: 20px;

        @include min($screen-md){
            margin-bottom: 33px;
        }
    }

    &__ac{
        border: none;
        border-bottom: 1px solid $gray;
        margin-top: 0;
    }

    &__trigger.ac-trigger{
        font-family: $gilroy;
        font-size: 32px;
        font-weight: 300;
        line-height: 1.2;
        text-transform: uppercase;
        padding: 19px 50px 19px 12px;
        display: flex;
        align-items: center;

        @include min($screen-sm){
            font-size: 32px;
        }

        @include min($screen-md){
            font-size: 36px;
            padding: 32px 96px 32px 36px;
        }

        @include min($screen-xl){
            padding: 31px 96px 31px 0;
        }

        //@include min($screen-xl){
        //    padding-left: 404px;
        //}

        //span{
        //    @include min($screen-md){
        //        display: block;
        //        width: calc(100% - 296px);
        //    }
        //
        //    @include min($screen-xl){
        //        width: 100%;
        //    }
        //}

        &:after{
            font-size: 38px;
            line-height: 1;
            right: 20px;

            @include min($screen-md){
                right: 40px;
            }

            @include min($screen-xl){
                right: 7px;
            }
        }

        &:focus{
            color: inherit;
        }
    }

    &__trigger-num{
        font-weight: 300;
        font-size: 20px;
        line-height: 90%;
        color: rgba($gray, 0.3);

        @include min($screen-xl){
            display: inline-block;
            vertical-align: middle;
            width: 440px;
        }
    }

    &__trigger-text{


        @include max($screen-xl){
            padding-left: 40px;
        }
    }

    &__content{
        padding-left: 62px;
        padding-right: 12px;
        padding-bottom: 20px;

        @include min($screen-md){
            //display: flex;
            padding-right: 96px;
            padding-left: 86px;
            padding-bottom: 30px;
        }

        @include min($screen-xl){
            margin-left: 440px;
            padding-left: 0;
            padding-right: 0;
            max-width: 415px;
        }
    }

    &__subtitle{
        font-size: 28px;
        font-weight: 300;
        line-height: 1.2;
        font-family: $gilroy;
        text-transform: uppercase;
        //margin-top: 20px;
        margin-bottom: 10px;

        @include min($screen-sm){
            font-size: 32px;
            //margin-top: 30px;
            margin-bottom: 20px;
        }
        @include min($screen-md){
            font-size: 36px;
        }
    }

    &__text{
        margin-top: 0;
    }

    &__text + &__text{
        margin-top: 20px;

        @include min($screen-md){
            margin-top: 30px;
        }
    }

    &__text + &__subtitle{
        margin-top: 20px;

        @include min($screen-sm){
            margin-top: 30px;
        }
    }

    &--product{
        #{$block-name}__trigger-text{
            padding-left: 0;
        }
    }
}
