.gallery-content{
    $block-name: &; // #{$block-name}__element

    &__item{
        padding-bottom: 50px;
        margin-bottom: 50px;
        border-bottom: 1px solid $gray;

        &:last-child{
            border-bottom: none;
            padding-bottom: 0;
        }
    }

    &__img{
        height: 300px;
        margin-bottom: 20px;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__date{
        font-weight: 300;
        font-size: 13px;
        line-height: 120%;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        font-family: $gilroy;
        margin-bottom: 20px;
    }

    &__title{
        font-weight: 300;
        font-size: 24px;
        line-height: 120%;
        text-transform: uppercase;
        font-family: $gilroy;
        color: $green;
    }
}
