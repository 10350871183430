.main-production{
    $block-name: &; // #{$block-name}__element

    &__container{
        @include max($screen-xl){
            padding: 0;
        }
    }

    &__title{
        margin-bottom: 23px;

        @include min($screen-md){
            margin-bottom: 33px;
        }
    }

    &__ac{
        position: relative;
        border: none;
        border-bottom: 1px solid $gray;
        margin-top: 0;

        &:after{
            content: '';
            background-color: $gray;
            width: 100vw;
            height: calc(100% + 2px);
            position: absolute;
            left: calc(((1296px - 100vw) / 2));
            display: none;
            top: -1px;
            z-index: -1;
        }

        &.is-active{
            background-color: $gray;
            color: $white;

            #{$block-name}__trigger{
                color: $white;
            }

            #{$block-name}__text{
                color: $white;
            }

            &:after{
                @include min($screen-xl){
                    display: block;
                }
            }
        }
    }

    &__trigger.ac-trigger{
        font-family: $gilroy;
        font-size: 32px;
        font-weight: 300;
        line-height: 1.2;
        text-transform: uppercase;
        padding: 19px 50px 19px 12px;

        @include min($screen-sm){
            font-size: 32px;
        }

        @include min($screen-md){
            font-size: 36px;
            padding: 31px 96px 31px 36px;
        }

        @include min($screen-xl){
            padding-left: 440px;
        }

        span{
            @include min($screen-md){
                display: block;
                width: calc(100% - 296px);
            }

            @include min($screen-xl){
                width: 100%;
            }
        }

        &:after{
            font-size: 38px;
            line-height: 1;
            right: 20px;
            margin-top: 4px;

            @include min($screen-md){
                right: 36px;
            }

            @include min($screen-xl){
                right: 7px;
            }
        }

        &:focus{
            color: inherit;
        }
    }

    &__panel{

        &--active{
            #{$block-name}__img{
                @include min($screen-md){
                    opacity: 1;
                    transition: opacity 0.1s 0.1s;
                }
            }
        }
    }

    &__content{
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 20px;

        @include min($screen-md){
            //display: flex;
            padding-right: 96px;
            padding-left: 36px;
            padding-bottom: 30px;
            //min-height: 130px;
        }

        @include min($screen-xl){
            padding-left: 440px;
        }
    }

    &__text{
        margin-bottom: 20px;

        @include min($screen-md){
            margin-bottom: 0;
            width: calc(100% - 296px);
        }
    }

    &__img{
        height: 172px;



        @include min($screen-md){
            position: absolute;
            right: 96px;
            top: 30px;
            //margin-top: -60px;
            opacity: 0;
            transition: none;
            flex-shrink: 0;
            width: 276px;
            height: calc(100% - 60px);

            @include min($screen-xl){
                height: calc(100% - 50px);
                top: 25px;
                right: auto;
                left: 0;
            }
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
