.footer {
    $block-name: &; // #{$block-name}__element

    padding-bottom: 40px;

    @include min($screen-md){
        padding-bottom: 45px;
    }

    &__wrapper{
        display: grid;
        grid-template-areas:
            'one two'
            'copy copy'
            'terms terms'
        ;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 65px;

        @include min($screen-md){
            grid-template-areas:
                'one one'
                'two two'
                'copy terms'
            ;
            grid-column-gap: 24px;
        }

        @include min($screen-xl){
            grid-template-areas:
                'copy one'
                'terms two'
            ;
            grid-template-columns: 320px 1fr;
            grid-column-gap: 123px;
        }
    }

    &__menu{
        margin-bottom: 20px;

        &--one{
            grid-area: one;

            @include min($screen-md){
                margin-bottom: 0;
            }

            @include min($screen-xl){
                margin-bottom: 32px;
            }
        }

        &--two{
            grid-area: two;

            @include min($screen-md){
                margin-bottom: 30px;
            }

            @include min($screen-xl){
                margin-bottom: 0;
            }
        }
    }

    &__copy{
        grid-area: copy;
        margin-bottom: 20px;

        @include min($screen-md){
            margin-bottom: 0;
        }

        @include min($screen-xl){
            padding-top: 10px;
        }
    }

    &__terms{
        grid-area: terms;

        @include min($screen-xl){
            padding-top: 10px;
        }
    }



}
