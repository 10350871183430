.about{
    $block-name: &; // #{$block-name}__element

    &__descr{
        @include min($screen-md){
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 24px;
            margin-bottom: 40px;
        }
    }

    &__text{
        margin-bottom: 20px;

        @include min($screen-md){
            margin-bottom: 0;
        }
    }

    &__logo{
        margin-bottom: 30px;

        @include min($screen-md){
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 24px;
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            margin-bottom: 50px;
        }
    }

    &__logo-wrap{
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        @include min($screen-md){
            margin-bottom: 0;
        }
    }

    &__logo-img{
        margin-bottom: 10px;
        margin-top: auto;

        @include min($screen-md){
            margin-bottom: 20px;
        }
    }

    &__logo-text{
        margin-top: auto;
    }

    &__list{
        display: grid;
        grid-template-columns: 1.3fr 0.7fr;
        grid-column-gap: 24px;
        margin-bottom: 80px;

        @include min($screen-md){
            //grid-template-columns: repeat(4, 1fr);
            grid-template-columns: 1.3fr 0.7fr 0.7fr 1.3fr;
            margin-bottom: 120px;
        }

        @include min($screen-xl){
            margin-bottom: 150px;
        }
    }

    &__item{
        &--order{
            @include min($screen-md){
                order: 2;
                justify-self: end;
            }
        }
    }

    &__item-num{
        font-weight: 300;
        font-size: 20px;
        line-height: 90%;
        font-family: $gilroy;
        margin-bottom: 20px;

        span{
            font-size: 40px;
            line-height: 120%;
        }

    }
}
