.detail{
    $block-name: &; // #{$block-name}__element

    &__content{
        margin-bottom: 50px;

        @include min($screen-md){
            margin-bottom: 70px;
        }

        @include min($screen-xl){
            margin-bottom: 100px;
        }

        &:last-child{
            margin-bottom: 40px;

            @include min($screen-xl){
                margin-bottom: 50px;
            }
        }
    }

    &__img{
        height: 600px;
        margin-bottom: 30px;

        @include min($screen-md){
            margin-bottom: 40px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__text{
        @include min($screen-md){
            width: 50%;
            margin-left: auto;
        }

        @include min($screen-xl){
            width: 68%;
        }

        p{
            margin-bottom: 20px;
        }

        &--small{
            margin: 0;

            @include max($screen-xl){
                width: 100%;
            }
        }

        &--column{
            @include min($screen-md){
                width: 100%;
                //display: grid;
                //grid-template-columns: 1fr 1fr;
                //grid-column-gap: 24px;
                 columns: 2;


            }

            @include min($screen-xl){
                width: 68%;
            }

            //p{
            //    @include min($screen-md){
            //        &:nth-child(2){
            //            order: 2;
            //        }
            //    }
            //
            //}

            p{
                //margin-bottom: 0;
                //margin-top: 20px;

                //&:first-child{
                //    margin-top: 0;
                //}

                @include min($screen-md){
                    display: inline-block;
                    vertical-align: top;
                    overflow: hidden; /* Fix for firefox and IE 10-11  */
                    -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
                    page-break-inside: avoid; /* Firefox */
                    break-inside: avoid; /* IE 10+ */
                    break-inside: avoid-column;
                }
            }
        }
    }

    &__back{
        @include min($screen-xl){
            width: 68%;
            margin-left: auto;
        }
    }
}
