.partners{
    $block-name: &; // #{$block-name}__element

    &__list{
        display: grid;
        grid-row-gap: 30px;
        margin-bottom: 30px;

        @include min($screen-md){
            grid-template-columns: 1fr 1fr;
            grid-gap: 40px 24px;
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            margin-bottom: 50px;
        }
    }

    &__title{
        font-size: 24px;
        font-weight: 300;
        line-height: 1.2;
        font-family: $gilroy;
        text-transform: uppercase;
        margin-bottom: 10px;

        @include min($screen-md){
            font-size: 32px;
            margin-bottom: 20px;
        }
    }

    &__btn{
        display: none;

        @include min($screen-md){
            display: flex;
        }
    }
}
