@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Light'), local('Gilroy-Light'),
    url('../fonts/Gilroy-Light.woff2') format('woff2'),
    url('../fonts/Gilroy-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PT Sans';
    src: url('../fonts/PTSans-Regular.woff2') format('woff2'),
    url('../fonts/PTSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

