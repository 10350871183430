// File for scss-vars.
// For example: main colors, paddings and so on.

// Шрифты
$gilroy: "Gilroy", "Helvetica", "Arial", sans-serif;
$sans: "PT Sans", "Helvetica", "Arial", sans-serif;

// Базовые цвета https://colorscheme.ru/color-converter.html

$black: #000;
$dark: #030404;
$white: #fff;
$gray: #343738;
$green: #2C9942;

//$gray-dark: #9AA9B1;
//$gray: #F4F5F6;
//$gray-concrete: #F3F3F3;
//$dark-gray: #1e1e1e;

//$link-line-gray: rgba($black-color, 0.2);
//$link-line-red: rgba($orange-color, 0.2);

$border-gray: #CFCFCF;


//$black: hsl(0, 0%, 0%);
//$gray-darkest: hsl(0, 0%, 10%);
$gray-darker: hsl(0, 0%, 20%);
//$gray-darken: hsl(0, 0%, 30%);
//$gray-dark: hsl(0, 0%, 40%);
//$gray-light: hsl(0, 0%, 60%);
//$gray-lighten: hsl(0, 0%, 70%);
$gray-lighter: hsl(0, 0%, 80%);
$gray-lightest: hsl(0, 0%, 90%);
//$white: hsl(0, 0%, 100%);
//
//$color-main: #048bcd;
//$color-success: hsl(120, 39%, 54%);
$color-danger: hsl(2, 64%, 58%);
//$color-warning: hsl(35, 84%, 62%);
//
//$color-vk: #4a76a8;
//$color-fb: #4267b2;
//$color-tw: #40a2f5;
//$color-gh: #24292e;
//
//// Семантические цвета
//
//$green-color: #25e1b1;
//$gray-color: #ececec;
//$gray-color2: #f2f2f2;
//
//$text-color: #36353a;
$text-color-muted: #7c8185;
//$body-bg: #ffffff;
//
//$link-color: $color-main;
//$link-color--hover: darken($color-main, 15%);
//
//$border-color: $white;
//$border-color2: #f0f0f0;

// Базовая типографика

$font-size: 16px;

$font-size-h1: 40px;
$font-size-h2: 32px;
$font-size-h3: 30px;
$font-size-h4: 18px;
$font-size-h5: 16px;
$font-size-h6: 16px;

$font-size-sm: .75em; // 12px от 16px

$line-height: 1.3;

$typo-margin-vertical: 24px;

$font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Ubuntu', 'Droid Sans', 'Helvetica Neue',
    'Arial', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family--monospace: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New,
    monospace;
// Georgia, Times New Roman, Times, serif

// Ширины

$screen-xs: 0;
$screen-sm: 480px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1350px;
$screen-xxl: 1920px;

$container-sm: 100%;
$container-md: 100%;
$container-lg: 100%;
//$container-xl: $screen-xl - 30;
//$container-xxl: $screen-xxl - 30;
$container-xl: 100%;
$container-xxl: 100%;

// Модульная сетка

$grid-columns: 12;
$grid-gutter-width: 40px;

// Разное

$field-padding-vertical: 14.6px;
$field-padding-horizontal: 20px;

$border-radius: 3px;
$opacity: .7;
$transition-time: .25s;
$shadow: 0 4px 2px -2px rgba(0, 0, 0, .3);

$menu-desktop-width: $screen-lg;
$toggler-part-height: 2px;
$toggler-part-bg-color: $black;

$z-index-modal: 100;
