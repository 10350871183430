.hero-text{
    $block-name: &; // #{$block-name}__element

    &--left{
        #{$block-name}__container{
            @include min($screen-xl){
                padding-left: 36px;
                padding-right: 476px;
            }
        }
    }

    &--catalog{
        #{$block-name}__container{
            @include min($screen-xl){
                padding-left: 36px;
            }
        }

        #{$block-name}__text{
            @include min($screen-md){
                max-width: 50%;
            }

            @include min($screen-xl){
                max-width: 855px;
            }
        }
    }

    &--project-detail{
        #{$block-name}__container{
            @include min($screen-xl){
                padding-left: 36px;
            }
        }

        #{$block-name}__text{
            @include min($screen-md){
                max-width: 50%;
            }

            @include min($screen-xl){
                max-width: 416px;
            }
        }
    }

    &--certificates{
        margin-bottom: 70px;

        @include min($screen-md){
            margin-bottom: 120px;
        }

        @include min($screen-xl){
            margin-bottom: 150px;
        }

        #{$block-name}__container{
            @include min($screen-xl){
                padding-left: 36px;
            }
        }

        #{$block-name}__text{
            @include min($screen-md){
                max-width: 336px;
            }

            @include min($screen-xl){
                max-width: 416px;
            }
        }
    }
}
