.news-item{
    $block-name: &; // #{$block-name}__element

    padding-bottom: 50px;
    margin-bottom: 50px;
    border-bottom: 1px solid $gray;

    //&:first-child{
    //    margin-top: 0;
    //}

    &:last-child{
        @include min($screen-md){
            border-bottom: none;
        }

        @include min($screen-xl){
            padding-bottom: 0;
        }
    }

    &__img{
        height: 200px;
        width: 100%;
        margin-bottom: 20px;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__date{
        font-weight: 300;
        font-size: 13px;
        line-height: 120%;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        color: $gray;
        margin-bottom: 20px;
    }

    &__title{
        font-weight: 300;
        font-size: 24px;
        line-height: 120%;
        text-transform: uppercase;
        color: $green;
        margin-bottom: 20px;
        font-family: $gilroy;
    }

    &__text{
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        max-height: 63px;
    }
}
