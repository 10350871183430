.news-detail{
    $block-name: &; // #{$block-name}__element

    &__img-block{
        margin-bottom: 30px;

        @include min($screen-md){
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            display: grid;
            grid-template-columns: 68% 1fr;
            grid-column-gap: 24px;
        }
    }

    &__img{
        height: 400px;
        margin-bottom: 20px;

        @include min($screen-md){
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            margin-bottom: 0;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__img-text{
        max-width: 100%;

        @include min($screen-xl){
            max-width: 416px
        }

        h4{
            font-weight: 300;
            font-size: 24px;
            line-height: 120%;
            font-family: $gilroy;
            text-transform: uppercase;
            margin-bottom: 20px;
        }

        p{
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            margin-bottom: 20px;
        }

        strong{
            //font-weight: 700;
            color: $dark;
        }
    }

    &__content{
        color: $gray;
        margin-bottom: 40px;

        @include min($screen-md){
            margin-bottom: 50px;
        }

        h3{
            font-weight: 300;
            font-size: 32px;
            line-height: 120%;
            font-family: $gilroy;
            text-transform: uppercase;
            margin-bottom: 20px;
        }

        p{
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            margin-bottom: 20px;
        }

        ul{
            margin-bottom: 20px;
            //padding-left: 30px;
            list-style-type: disc;
            list-style-position: inside;
        }

        li{
            font-size: 16px;
            font-weight: 400;
            line-height: 130%;
        }

        &--small{
            @include min($screen-xl){
                width: 68%;
            }
        }
    }
}
