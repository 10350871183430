.product-top{
    $block-name: &; // #{$block-name}__element


    &__title{
        font-weight: 300;
        font-size: 48px;
        line-height: 120%;
        font-family: $gilroy;
        text-transform: uppercase;
        margin-bottom: 30px;

        @include min($screen-md){
            margin-bottom: 50px;
        }

        @include min($screen-xl){
            font-size: 64px;
        }
    }

    &__grid{
        @include min($screen-xl){
            display: grid;
            grid-template-columns: 1fr 416px;
            grid-column-gap: 24px;
        }
    }

    &__image{
        height: 400px;
        margin-bottom: 20px;

        @include min($screen-md){
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            margin-bottom: 0;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__text{
        margin-bottom: 20px;

        @include min($screen-md){
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 24px;
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            display: block;
        }

        p{
            margin-top: 0;
        }

        p + p{
            margin-top: 20px;

            @include min($screen-md){
                margin-top: 0;
            }

            @include min($screen-xl){
                margin-top: 30px;
            }
        }
    }

    &__link{
        display: inline-flex;
        align-items: center;

        svg{
            margin-right: 10px;
        }
    }
}
