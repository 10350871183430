.contacts{
    $block-name: &; // #{$block-name}__element

    &__container{
        @include max($screen-xl){
            padding: 0;
        }
    }

    &__grid{
        margin-bottom: 30px;

        @include min($screen-md){
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 24px;
            margin-bottom: 40px;
        }
    }

    &__contacts{
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 24px;
        line-height: 130%;
        margin-bottom: 30px;

        @include min($screen-md){
            margin-bottom: 0;
        }
    }

    &__phone{
        margin-bottom: 20px;
    }

    &__mail{
        margin-bottom: 30px;
    }

    &__label{
        font-weight: 300;
        font-size: 13px;
        line-height: 120%;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        font-family: $gilroy;
        margin-bottom: 10px;
    }

    &__address + &__address{
        margin-top: 30px;
    }

    &__address-text{
        br{
            @include min($screen-xl){
                display: none;
            }
        }
    }

    &__form{
        margin-top: 30px;
        margin-bottom: 30px;

        @include min($screen-md){
            max-width: 336px;
            margin-bottom: 50px;
        }

        @include min($screen-xl){
            margin-top: 50px;
            max-width: 416px;
        }
    }

    &__field{
        margin-bottom: 20px;
    }

    &__form-bottom{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 24px;
    }

    &__personal{
        font-size: 13px;
    }

    &__btn{
        align-self: start;
    }

    &__map{
        height: 600px;

        @include min($screen-md){
            height: 800px;
        }

        img, iframe{
            height: 100%;
            width: 100%;
            object-fit: cover;
            border: none;
        }
    }
}
