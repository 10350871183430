.descr-img{
    $block-name: &; // #{$block-name}__element

    &__grid{
        @include min($screen-lg){
            display: grid;
            grid-template-columns: 1fr 416px;
            grid-column-gap: 24px;
        }
    }

    &__image{
        height: 400px;
        margin-left: -12px;
        margin-right: -12px;
        margin-bottom: 20px;

        @include min($screen-md){
            margin-left: -36px;
            margin-right: -36px;
            margin-bottom: 40px;
            height: 600px;
        }

        @include min($screen-lg){
            margin: 0;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content{
        @include min($screen-md){
            max-width: 336px;
            width: 100%;
            margin-left: auto;
        }

        @include min($screen-lg){
            max-width: none;
            margin: 0;
        }

        p{
            margin-top: 0;
        }

        p + p{
            margin-top: 20px;
        }
    }

    &__link{
        display: inline-block;
        margin-top: 20px;

        @include min($screen-md){
            margin-top: 30px;
        }
    }
}
