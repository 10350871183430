.catalog-item{
    $block-name: &; // #{$block-name}__element

    &__grid{
        @include min($screen-md){
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 24px;
            align-items: start;
        }
    }

    &__image{
        position: relative;
        margin-bottom: 20px;

        @include min($screen-md){
            margin-bottom: 0;
        }

        &:before{
            content: '';
            padding-top: 200px;
            display: block;

            @include min($screen-md){
                padding-top: 100%;
            }
        }

        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__title{
        font-family: $gilroy;
        font-weight: 300;
        line-height: 1.2;
        font-size: 24px;
        text-transform: uppercase;
        margin-bottom: 20px;

        @include min($screen-md){
            font-size: 32px;
        }
    }

    &__label{
        padding: 5px 10px;
        background-color: $green;
        color: $white;
        margin-bottom: 30px;
    }

    &__text{
        margin-bottom: 20px;

        @include min($screen-md){
            margin-bottom: 30px;
        }
    }

    &__list{
        list-style-type: disc;
        list-style-position: inside;
        padding-left: 5px;
        margin-bottom: 30px;
    }

    &__size{
        margin-left: 10px;
    }
}
