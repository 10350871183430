.mega-menu{
    $block-name: &; // #{$block-name}__element

    background-color: $white;
    padding-top: 50px;
    padding-bottom: 50px;

    &__wrapper{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 24px;
    }

    &__title{
        margin-bottom: 10px;
        font-family: $gilroy;
        font-weight: 300;
        font-size: 24px;
        line-height: 120%;
        text-transform: uppercase;
        color: $green;

        &:last-child{
            margin-bottom: 0;
        }
    }

    &__subtitle{
        margin-bottom: 10px;
        font-family: $gilroy;
        font-weight: 300;
        font-size: 13px;
        line-height: 120%;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        color: $gray;
    }

    &__list-about{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__list{
        margin-bottom: 30px;

        + #{$block-name}__title{
            margin-top: 40px;
        }
    }

    &__link{
        display: inline-block;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        text-transform: uppercase;
        padding: 10px 0;

    }

    &--about{
        #{$block-name}__link{
            padding: 0 20px;
        }
    }


}
