.certificates{
    $block-name: &; // #{$block-name}__element

    &__wrapper{
        @include min($screen-xl){
            display: grid;
            grid-template-columns: 306px 1fr;
            grid-column-gap: 134px;
        }
    }

    &__links{
        margin-bottom: 40px;

        @include min($screen-md){
            margin-bottom: 50px;
        }
    }

    &__links-wrap{
        position: sticky;
        top: 0;
    }

    &__link{
        display: block;

        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        padding-top: 10px;
        padding-bottom: 10px;

        @include min($screen-md){
            display: inline-block;
            padding: 0 40px 0 0;

            &:last-child{
                padding-right: 0;
            }
        }

        @include min($screen-xl){
            display: block;
            font-weight: 300;
            font-size: 24px;
            line-height: 120%;
            text-transform: uppercase;
            color: $gray;
            font-family: $gilroy;
            padding: 20px 12px;
            border-bottom: 1px solid $gray;

            &:hover, &:focus-visible{
                span{
                    border-bottom: none;
                }
            }

            span{
                border: none;
            }
        }

    }


    &__list{
        @include min($screen-md){
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 24px;
        }
    }

    &__item{
        @include min($screen-md){
            .certificates-item{
                &:last-child{
                    border-bottom: none;
                }

            }
        }

        &:last-child{
            .certificates-item{
                &:last-child{
                    border-bottom: none;
                }
            }
        }
    }

    &__title{
        font-weight: 300;
        font-size: 40px;
        line-height: 120%;
        color: $green;
        text-transform: uppercase;
        font-family: $gilroy;
        margin-bottom: 30px;

        @include min($screen-md){
            font-size: 48px;
            margin-bottom: 50px;
        }
    }
}
