.counters{
    $block-name: &; // #{$block-name}__element

    &__list{
        display: grid;
        grid-template-columns: 1.19fr 0.81fr;

        grid-gap: 20px;

        @include min($screen-md){
            justify-content: space-between;
            grid-template-columns: repeat(4, auto);
        }
    }

    &__item{
        &--last{
            @include min($screen-md){
                order: 2;
            }
        }
    }

    &__top{
        font-family: $gilroy;
        font-weight: 300;
        margin-bottom: 20px;
    }

    &__num{
        font-size: 40px;
        line-height: 1.2;
    }

    &__descr{
        font-size: 20px;
        line-height: 90%;
        margin-left: 10px;
    }
}
