.main-logo{
    $block-name: &; // #{$block-name}__element

    margin-bottom: 30px;

    @include min($screen-md){
        margin-bottom: 40px;
    }

    @include min($screen-xl){
        margin-bottom: 50px;
    }

    &__logo{
        max-width: 336px;
        margin-bottom: 30px;

        @include min($screen-md){
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            max-width: 415px;
            margin-bottom: 50px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content{
        @include min($screen-md){
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 24px;
        }
    }

    &__text{
        margin-top: 0;
    }

    &__text + &__text{
        margin-top: 20px;

        @include min($screen-md){
            margin-top: 0;
        }
    }
}
