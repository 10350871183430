.catalog-list{
    $block-name: &; // #{$block-name}__element

    &__item{
        margin-bottom: 30px;

        @include min($screen-md){
            margin-bottom: 40px;
        }

        &:last-child{
            margin-bottom: 0;
        }
    }
}
