.modal-menu{
    $block-name: &; // #{$block-name}__element

    //padding: 30px 12px;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__list{
        margin: auto 0;
    }

    &__item{
        text-align: center;
        border-bottom: 1px solid $gray;

        &--green{
            border-bottom: none;

            #{$block-name}__link{
                color: $white;
                background-color: $green;
            }
        }
    }

    &__link{
        display: inline-block;
        width: 100%;
        font-weight: 300;
        font-size: 32px;
        line-height: 120%;
        font-family: $gilroy;
        padding: 30px;
        color: $gray;
        text-transform: uppercase;
    }
}
