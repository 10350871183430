:root{
    --mm-ocd-width: 100%;
    --mm-ocd-max-width: 100%;
    --mm-spn-item-height: 90px;
}

.mobile-menu{
    $block-name: &; // #{$block-name}__element

    @include min($screen-lg){
        display: none;
    }

    //display: none;
    //position: absolute;
    //top: 96px;
    //left: 0;
    //background-color: $white;
    //z-index: 100;
    //width: 100%;

    &__link{
        display: inline-block;
        width: 100%;
        font-weight: 300;
        font-size: 24px;
        line-height: 120%;
        text-transform: uppercase;
        font-family: $gilroy;
        color: $gray;
        padding: 30px 12px;
        border-bottom: 1px solid $gray;

        @include min($screen-md){
            padding: 30px 36px;
        }
    }

    &.mm-spn.mm-spn--navbar.mm-spn--main:after{
        //display: none;
        height: 0;
    }

    &.mm-spn.mm-spn--navbar.mm-spn--main {
        ul {
            top: 0;

            &:before{
                display: none;
            }
        }
    }

    &.mm-spn.mm-spn--navbar {
        &:before{
            border-color: $gray;
            opacity: 1;
            left: 12px;

            @include min($screen-md){
                left: 36px;
            }
        }

        &:after{
            font-weight: 300;
            font-size: 24px;
            line-height: 90px;
            height: 90px;
            text-transform: uppercase;
            font-family: $gilroy;
            color: $green;
            opacity: 1;
            text-align: left;
            padding-left: 42px;

            @include min($screen-md){
                padding-left: 66px;
            }

        }

        ul:before {
            opacity: 1;
            border-color: $gray;
        }
    }

    &.mm-spn {
        background-color: $white;

        ul {
            &:after {
                display: none;
            }
        }

        li {
            &:before {
                border-color: $gray;
                opacity: 1;
                right: 22px;

                @include min($screen-md){
                    right: 46px;
                }
            }
        }

        li {
            &:after {
                margin: 0;
                border-top: 1px solid $gray;
                opacity: 1;
            }
        }

        a, span{
            font-weight: 300;
            font-size: 24px;
            line-height: 120%;
            text-transform: uppercase;
            font-family: $gilroy;
            padding: 30px 12px;

            @include min($screen-md){
                padding: 30px 36px;
            }
        }

        .btn--green{
            background-color: $green;
            color: $white;
            text-align: center;
            margin-top: -1px;

            @include min($screen-md){
                display: none;
            }

            &:after {
                border: none;
            }

            &:before{
                display: none;
            }
        }
    }







    //&--show{
    //    display: block;
    //}
}



.mm-ocd{
    top: 96px;
    //overflow: visible;
    //bottom: 0;
}



//.mm-ocd--open .mm-ocd__backdrop{
//    //top: -96px;
//    width: 100%;
//    height: var(--mm-spn-item-height);
//}

//.mm-spn li:before{
//    display: none;
//}

//.mm-spn a, .mm-spn span{
//    padding: 0;
//    width: 100%;
//}
//
//.mm-spn a:after{
//    display: none;
//}
//
//.mm-spn a:not(:last-child):after{
//    display: none;
//}
