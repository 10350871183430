.certificates-item{
    $block-name: &; // #{$block-name}__element

    padding-bottom: 30px;
    border-bottom: 1px solid $gray;
    margin-bottom: 30px;

    @include min($screen-md){
        padding-bottom: 50px;
        margin-bottom: 50px;
    }

    &__name{
        font-size: 13px;
        font-weight: 300;
        line-height: 1.2;
        font-family: $gilroy;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    &__title{
        font-family: $gilroy;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 120%;
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    &__link{
        display: flex;
        align-items: center;

        > span{
            margin-top: 2px;
            font-size: 13px;
            margin-left: 5px;
            line-height: 130%;
        }
    }
}
